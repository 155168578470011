.App-content {
  flex: 1;
}

.btn-input {
  height: 50px;
}

.flex-vertical {
  display: flex;
  flex-direction: column;
}

.flex {
  display: flex;
}

.flex-stretch {
  flex: 1;
}

.vertical-align {
  display: flex;
  align-items: center;
}

.form-group-edit {
  margin-top: 24px;
}

html {
  overflow-y: scroll;
}

/*
    Fix for Add pickup dialog (to pickup calendar)
    Without this are items in calendar select invisible (behind tooltip element)
*/
body .tether-tooltip-element {
  z-index: 1001;
}

.btn i.icon-on-right {
  padding-right: 0px;
  padding-left: 8px;
}

.flex-align-items-center {
  display: flex;
  align-items: center;
}

.padding-top {
  padding-top: 8px;
}

.navbar .nav-tabs li.icon-link a.clickable {
  display: flex;
}

.card-item {
  margin-bottom: 24px;
}

.padding-top-32 {
  padding-top: 32px;
}

.text-align-right {
  text-align: right;
}

.row.display-flex {
  display: flex;
  flex-wrap: wrap;
}
.row.display-flex > [class*='col-'] {
  display: flex;
  flex-direction: column;
}

#root,
html,
body {
  height: 100%;
  margin: 0;
  overflow-x: hidden;
}

.scrollWrapper {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.overflow-y-only {
  overflow-y: auto;
  overflow-x: hidden;
}

.main-container {
  padding-right: 15px;
  padding-left: 15px;
}

.padding-bottom-full {
  padding-bottom: 16px;
}
