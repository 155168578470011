.cell {
  position: sticky;
  height: 56px;
  margin-bottom: 24px;
  top: 0;
  background-color: var(--white);
  border-bottom: 2px solid var(--platinum);
  z-index: 1;
}

.cell > div {
  width: 100%;
  height: 100%;
}

.title {
  font-weight: normal;
  padding-left: 8px;
  padding-top: 8px;
  border: 1px solid transparent;
  width: 100%;
  height: 100%;
}

.title .dayName {
  font-size: 10px;
  line-height: 12px;
  color: var(--slate);
}

.title .dayDate {
  font-size: 18px;
  color: var(--working-day);
}

.title:global(.rdtToday) > div {
  font-weight: bold;
}

.title:global(.rdtOld) > div {
  color: var(--alloy);
}

.title:global(.rdtDisabled) {
  background: linear-gradient(
      to right bottom,
      var(--platinum) 49%,
      var(--white) 49%,
      var(--white) 51%,
      var(--platinum) 51%
    )
    no-repeat;
  border-right: 1px solid var(--white);
}

.title:global(.holiday) {
  border: 1px solid var(--warning-base);
}

.title:global(.rdtOld.holiday) {
  border: 1px solid var(--warning-pale);
}

.title:global(.rdtDisabled) .dayDate {
  color: var(--slate);
}
.cell .title:global(.exception):after {
  content: '';
  height: 8px;
  width: 8px;
  background-color: var(--info-base);
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: 6px;
  right: 6px;
}
