:global(.checkbox).color-checkbox {
  --color: black;
  margin-top: 0;
  margin-bottom: 0;
}
.color-checkbox.unchecked svg use {
  fill: var(--color);
}

.color-checkbox.checked svg g g:first-of-type {
  fill: var(--color);
}
