.calendar-legend .fa-ul {
  margin-left: 0px;
}
.calendar-legend .fa-ul li {
  display: flex;
  align-items: center;
  min-height: 30px;
}
.calendar-legend .legend-glyph {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}
.calendar-legend .public-holidays {
  background: linear-gradient(
    -45deg,
    var(--platinum) 48%,
    var(--white) 48%,
    var(--white) 52%,
    var(--platinum) 52%
  );
  border: 1px solid var(--warning-base);
}

.calendar-legend .plant-calendar-legend {
  padding-bottom: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.calendar-legend .nonworking-days {
  background: linear-gradient(
    -45deg,
    var(--platinum) 48%,
    var(--white) 48%,
    var(--white) 52%,
    var(--platinum) 52%
  );
  border: 1px solid var(--platinum);
}
.calendar-legend .working-days {
  background: var(--white);
  border: 1px solid var(--platinum);
}
