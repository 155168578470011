.weeklyCalendar {
  min-height: 0;
  --weekCalendarEventHeight: 52px;
  --timeColumnWidth: 30px;
}

.scrollWrapper {
  overflow-y: auto;
  height: 100%;
}

.calendarBody {
  display: grid;
  grid-template-columns: var(--timeColumnWidth) repeat(7, minmax(25px, 1fr));
}

div.scaleCell {
  line-height: 28px;
  text-align: right;
  font-size: 10px;
  color: var(--alloy);
  width: var(--timeColumnWidth);
  border-top: none;
  background-color: var(--white);
  border-right: 1px solid var(--alloy);
}

.scaleHeader {
  margin-top: -13px;
  margin-right: 10px;
}

.scaleHeader.thickText {
  font-weight: 900;
  color: var(--info-darkest);
}

.dayCell {
  min-height: var(--weekCalendarEventHeight);

  border-top: 1px solid var(--alloy);
  border-right: 1px solid var(--alloy);
}

.dayCell.noEmptyBody {
  min-height: 0px;
}

.dayCell > div:last-child {
  margin-bottom: 10px;
}

:global(.tether-tooltip-target) .dayCell > div:last-child {
  margin-bottom: 0px;
}

.dayCell > :global(.tether-tooltip-target) {
  width: 100%;
}

.dayCell.rdtDisabled {
  background: var(--platinum);
  width: 100%;
  height: 100%;
}

.dayCell.pastCell {
  background: var(--horizon);
}

.dayCell.lastRow {
  border-bottom: 1px solid var(--alloy);
}

.dayCell.lateStart {
  padding-top: 10px;
}

.dayCell.rdtOld {
  background: var(--horizon);
}

.pickupEvent {
  --color: black;
  padding-left: 10px;
  padding-top: 10px;
  padding-right: 6px;
  border-bottom: 1px solid var(--white);
  background-color: var(--color);
  font-size: 12px;
  height: 52px;
  color: var(--white);
}

.pickupEvent > h5 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  margin: 0px;
  padding: 0px;
  font-weight: 300;
  padding-bottom: 4px;
}

.rdtOld.pickupEvent {
  opacity: 0.4;
}
