.calendar-content {
  height: calc(100vh - 245px);
}

.year-calendar {
  flex: 1;
  min-width: 0;
}

.year-calendar .control {
  padding-left: 20px;
}

.year-calendar .control .btn {
  font-size: 16px;
  padding: 0;
}

.year-calendar .control .btn {
  margin: 5px;
  width: auto;
}

.year-calendar .btn svg {
  fill: var(--ocean-base);
}

.month-display.rdtPicker {
  display: block;
  position: static;
  box-shadow: none;
  border: none;
  padding: 0;
  height: 265px;
}
.month-display.rdtPicker th {
  min-width: 24px;
  height: 28px;
  border: none;
}

.month-display.rdtPicker td {
  border-radius: 2px;
  min-width: 24px;
  height: 28px;
}

.month-display thead tr:first-child th {
  min-width: 28px;
  height: 28px;
  border: none;
  text-align: left;
  cursor: auto;
}
.month-display thead tr:first-child th:hover {
  background: none;
  color: var(--coal);
}

.month-display.rdtPicker td.rdtDisabled,
.month-display.rdtPicker td.rdtDisabled:hover {
  background: linear-gradient(
      to right bottom,
      var(--platinum) 48%,
      var(--white) 48%,
      var(--white) 52%,
      var(--platinum) 52%
    )
    no-repeat;
  color: var(--shale);
}

.month-display.rdtPicker td.rdtOld.rdtDisabled,
.month-display.rdtPicker td.rdtOld.rdtDisabled:hover,
td.rdtNew.rdtDisabled,
.month-display.rdtPicker td.rdtNew.rdtDisabled:hover {
  background: linear-gradient(
      to right bottom,
      var(--silver) 48%,
      var(--white) 48%,
      var(--white) 52%,
      var(--silver) 52%
    )
    no-repeat;
  color: var(--slate);
}

.month-display td.rdtOld {
  background: none;
  color: var(--alloy);
  cursor: not-allowed;
}

.month-display td.rdtOld:hover {
  background: none;
  color: var(--alloy);
  cursor: not-allowed;
}
.month-display.rdtPicker td.rdtDisabled:hover {
  background: linear-gradient(
      to right bottom,
      var(--sky-base) 48%,
      var(--white) 48%,
      var(--white) 52%,
      var(--sky-base) 52%
    )
    no-repeat;
  cursor: pointer;
}

.month-display.rdtPicker td.rdtOld.rdtDisabled:hover {
  background: linear-gradient(
      to right bottom,
      var(--silver) 48%,
      var(--white) 48%,
      var(--white) 52%,
      var(--silver) 52%
    )
    no-repeat;
  cursor: not-allowed;
}
.month-display.rdtPicker td.empty {
  cursor: default;
  background: var(--white);
}

.month-display.rdtPicker td.empty:hover {
  cursor: default;
  background: var(--white);
}

.month-display.rdtPicker td.rdtDay.holiday {
  border-color: var(--warning-base);
  border-width: 1px;
  border-style: solid;
}
.month-display.rdtPicker td.rdtOld.holiday {
  border-color: var(--warning-pale);
  border-width: 1px;
  border-style: solid;
}

.month-display.rdtPicker td.rdtDay {
  position: relative;
  border: 1px solid transparent;
}
.month-display.rdtPicker td.rdtDay.exception:after {
  content: '';
  height: 6px;
  width: 6px;
  background-color: var(--info-base);
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: 2px;
  right: 2px;
}

.month-display.rdtPicker .past-month {
  color: var(--alloy);
}
.month-display.rdtPicker table thead tr th.past-month:hover {
  color: var(--alloy);
}
.month-display.rdtPicker .past-month .dow {
  color: var(--alloy);
}

.month-display.rdtPicker td.rdtDay.selected {
  border: 1px solid var(--ocean-base);
}

.month-display td > .tether-tooltip-target {
  width: 100%;
  height: 26px;
  line-height: 26px;
}
