button:global(.btn.btn-link).linkButton {
  padding: 10px 4px 10px 2px;
  height: 40px;
}

button:global(.btn.btn-link).linkButton:hover {
  background-color: var(--white);
  border: 1px solid var(--white);
}

button:global(.btn.btn-link).linkButton:focus {
  background-color: var(--white);
  border: 1px solid var(--white);
  outline: none;
}

.transitCalendarRow {
  flex: 1 1 auto;
}

.transitCalendarRowContext {
  flex: 0 1 15px;
  padding-top: 13px;
}

.transitCalendarRowScrollbar {
  flex: 0 1 14px;
}

.selectTransitSchedule {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;
}

.scheduleNewTransits {
  padding-top: 8px;
  border-top: 1px solid var(--alloy);
  position: sticky;
  bottom: 0;
  background-color: var(--white);
}

.rowHeight {
  height: 37px;
}
