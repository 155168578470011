.calendar-legend .pickup-row .color-checkbox {
  margin: 0px;
}

.with-button-on-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
}

.with-button-on-right div:last-child {
  margin-left: 0;
}

.with-button-on-right .button-on-right {
  margin-left: auto;
  width: 16px;
}

.with-button-on-right .button-on-right i {
  width: 16px;
  text-align: center;
}

.pickup-row {
  margin: auto -16px;
  padding: 0 16px;
}

.pickup-row:hover {
  background-color: var(--horizon);
}

.pickup-row .overflow-tooltip {
  width: 90%;
}

.pickup-row .overflow-tooltip div label {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 6px;
}

.popover.pickup-calendar-legend-popover {
  padding: 0px;
  min-width: 200px;
}
.popover.pickup-calendar-legend-popover .btn {
  text-align: left;
}

.tether-tooltip-target-attached-bottom .popover.pickup-calendar-legend-popover {
  top: -6px;
}

.pickupCalendarList {
  max-height: calc(100vh - 500px);
}
