.row {
  flex: 1 1 auto;
}

.rowScrollbar {
  flex: 0 1 14px;
}

.rowHeight {
  height: 37px;
}
