.marginBottomFull {
  margin-bottom: 24px;
}

.topCarriers:global(.card-block) {
  padding: 0px;
}

.topCarriers:global(.card-block) :global(.list-group) {
  box-shadow: none;
  margin-bottom: 0px;
}

.topCarriers:global(.card-block) :global(.list-group-item) {
  border: none;
  border-bottom: 2px solid var(--alloy);
}
.topCarriers:global(.card-block) :global(.list-group-item):last-child {
  border: none;
}

.title {
  font-size: 12px;
  color: var(--shale);
}

.value {
  font-size: 18px;
}

.bold {
  font-weight: 600;
}

.textAlignCenter {
  text-align: center;
}

.paddingTop {
  padding-top: 8px;
}
