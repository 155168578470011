.add-pickup-dialog .form-group {
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 0px;
}

.add-pickup-dialog .calendar-select .react-select-wrapper {
  width: 100%;
}

.add-pickup-dialog-modal > .modal-content > .modal-header {
  padding: 0;
}

.add-pickup-dialog-modal > .modal-content > .modal-body {
  padding: 0;
}

.add-pickup-dialog .react-select-wrapper div[class*='-SingleValue'] {
  padding: 0 16px;
}
