.pickupEventPreview {
  --color: black;
  padding-left: 4px;
  padding-top: 6px;
  margin-left: -1px;
  margin-top: -1px;
  border: 1px solid var(--info-darkest);
  background-color: var(--sky-base);
  color: var(--white);
  font-size: 12px;
  width: calc(100% + 2px);
  height: 50px;
  top: 0;
  left: 0;
}

.pickupEventPreview > div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pickupEventPreview .carrierName {
  font-weight: bold;
}
