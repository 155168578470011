.marginBottomFull {
  margin-bottom: 24px;
}

.tooltip:hover {
  cursor: pointer;
}

.textAlignCenter {
  text-align: center;
}

.title {
  font-size: 12px;
  color: var(--shale);
  padding-bottom: 24px;
}

.value {
  font-weight: 600;
}
