.navigationBar :global(.btn-group-justified) {
  table-layout: auto;
}

.navigationBar :global(.btn-group .btn) {
  min-width: 60px;
  padding: 4px 14px;
}

.navigationBar {
  display: flex;
  margin-bottom: 10px;
}

.navigationBar .header {
  font-size: 16px;
  line-height: 34px;
}
